import 'bootstrap';

import LazyLoad from "vanilla-lazyload";

window.Utils = require('../javascripts/admin/Utils').default;

$(()=> {
	new Utils();
	// CKEDITOR.replace( 'ckeditor' );
	if (typeof initPage == 'function') {
		initPage();
	}
	new LazyLoad();

});

import 'stylesheets/magazine.scss';
