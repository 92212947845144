export default class Utils{

  constructor(){
		this.initNoFocus();
    this.allowTransitions();
  }


  allowTransitions() {
    $("body").removeClass("preload");
  }

  initNoFocus(event){
    $(document).on("focus",".no-focus",event=>{
      let $current = $(event.currentTarget);
      $current.blur();
    })
  }

}
